.jmenu {
    max-width: 320px;
}

.jmenu:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    text-shadow: 0 0 5px #fff;
}

.jmenu:not(:empty) {
    min-width: 280px;
}

.jmenu:empty {
    display: none;
}

.jmenu .close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: none;
    cursor: pointer;
}

.jmenu-logo {
    display: none;
}

.jmenu nav {
    position: relative;
    margin-bottom: 2px;
}

.jmenu nav h2 {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1em;
    padding: 10px;
    padding-left: 15px;
    line-height: 24px;
    border-radius: 4px;
    margin: 0px;
    letter-spacing: 1.1px;
}

.jmenu nav.selected h2 {
    background-color:  #f3f3f3;
}

.jmenu nav h2:hover {
    background-color:  #f3f3f3;
}

.jmenu nav h2[data-icon]:before {
    content: attr(data-icon);
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    font-family: 'Material icons';
    margin-right: 6px;
    font-weight: normal;
}

.jmenu nav h2::after {
    position: absolute;
    right: 6px;
    width: 18px;
    height: 18px;
    content: '';
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='18px' height='18px'%3E%3Cpath d='M10 17l5-5-5-5v10z'/%3E%3Cpath d='M0 24V0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    transition: transform .25s ease-in-out;
}

.jmenu nav.selected h2::after {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.jmenu nav > div {
    padding: 15px;
    display: none;
}

.jmenu nav.selected > div {
    display: block;
}

.jmenu nav > div > div {
    margin-bottom: 15px;
}

.jmenu nav h3 {
    font-size: 1em;
    font-weight: normal;
    color: #757575;
    margin-left: 10px;
    margin-bottom: 10px;
}

.jmenu ul {
    margin: 0;
    padding-left: 20px;
    padding-bottom: 10px;
    list-style: none;
}

.jmenu li {
    margin-bottom: 6px;
}

.jmenu li > ul {
    margin-top: 8px;
    position: relative;
}

.jmenu a {
    color: #000;
    text-decoration: none;
}

.jmenu a:hover {
    text-decoration: underline;
}

.jmenu a.selected {
    border-left: 4px solid var(--button-color);
    padding-left: 10px;
    margin-left: -14px;
}

.jmenu a.jmenu-subitem.selected {
    padding-left: 24px;
    margin-left: -28px;
}

.jmenu .title {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 5px;
}

.jmenu-subitem::before {
    content: '';
    position: absolute;
    margin-left: -20px;
    margin-top: 2px;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='18px' height='18px'%3E%3Cpath d='M10 17l5-5-5-5v10z'/%3E%3Cpath d='M0 24V0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    transition: transform .25s ease-in-out;
}

.jmenu-subitem ~ ul {
    display: none;
}

.jmenu-subitem.opened::before {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.jmenu-subitem.opened ~ ul {
    display: block;
}

@media (min-width: 1024px) {
    .jmenu-icon {
        display: none;
    }
}

@media (max-width: 1024px) {
    .jmenu {
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color:#fff;
        z-index:9000;
        overflow-y: scroll;
        overflow-x: hidden;
        box-shadow: 1px 1px 0 1px #ccc;
        padding: 20px;
        display: none;
    }

    .jmenu li {
        margin-bottom: 10px;
    }

    .jmenu-logo {
        display: block;
        max-height: 50px;
        margin-bottom: 20px;
        max-width: 120px;
    }

    .jmenu .close {
        display: block;
    }

    .jmenu-icon {
        display: block;
    }
}

.jmenu div::-webkit-scrollbar {
    width: 12px;
}

.jmenu div::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.jmenu div:hover {
    scrollbar-color: initial;
}

.jmenu div:hover::-webkit-scrollbar-thumb {
    background-color: #AAA;
}
